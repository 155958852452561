export const LS_USER_TYPE = 'huglo:userType';
export const LS_LOCALITY = 'huglo:locality';
export const LS_AVAILABLE_DISTRIBUTORS = 'huglo:availableDistributors';
export const LS_AVAILABLE_DISTRIBUTORS_COUNT =
  'huglo:availableDistributorCount';
export const LS_SELECTED_DISTRIBUTOR = 'huglo:selectedDistributor';
export const LS_CLIMATE_ZONE = 'huglo:climateZone';
export const LS_BILL_PRESENCE_TYPE = 'huglo:billPresenceType';
export const LS_HOUSEHOLD_SIZE = 'huglo:householdSize';
export const LS_HAS_CONTROLLED_LOAD = 'huglo:hasControlledLoad';
export const LS_CONTROLLED_LOAD_USAGE = 'huglo:controlledLoadUsage';
export const LS_HAS_SOLAR = 'huglo:hasSolar';
export const LS_HAS_LIFE_SUPPORT_EQUIPMENTS = 'huglo:hasLifeSupportEquipment';
export const LS_HAS_SAVE_LEAST = 'huglo:hasSaveLeast';
export const LS_FULL_NAME = 'huglo:fullName';
export const LS_EMAIL = 'huglo:email';
export const LS_MEMBER_ID = 'huglo:memberId';
export const LS_SOLAR_DETAILS = 'huglo:solarDetails';
export const LS_SOLAR_CREDITS = 'huglo:solarCredits';
export const LS_TARIFF_TYPE = 'huglo:tariffType';
export const LS_SINGLE_RATE_USAGE = 'huglo:singleRateUsage';
export const LS_START_DATE = 'huglo:startDate';
export const LS_END_DATE = 'huglo:endDate';
export const LS_PEAK_OFF_PEAK_USAGE = 'huglo:peakOffPeakUsage';
export const LS_BLOCK_RATES_USAGE = 'huglo:blockRatesUsage';
export const LS_CURRENT_RETAILER = 'huglo:currentRetailer';
export const LS_CURRENT_PLAN = 'huglo:currentPlan';

export const LS_RESIDENCE_ID = 'huglo:residenceId';
export const LS_BUSINESS_ID = 'huglo:businessId';
export const LS_DATA_RECOMMENDATION = 'huglo:dataRecommendation';
export const LS_PLANS_LIST_FINAL = 'huglo:displayedPlansList';
export const LS_IS_ACTUAL_PLAN_USER_SELECTED = 'huglo:isActualPlanUserSelected';
export const LS_ACTUAL_PLAN_DATA = 'huglo:actualPlanData';
export const LS_MEDIAN_PLAN_FINAL_COST = 'huglo:medianPlanFinalCost';
export const LS_SELECTED_RECOMMENDED_PLAN = 'huglo:selectedRecommendedPlan';
export const LS_SELECTED_RECOMMENDED_PLAN_INDEX =
  'huglo:selectedRecommendedPlanIndex';

export const LS_PARSED_BILL = 'huglo:parsedBill';
export const LS_PARSED_BILL_ID = 'huglo:parsedBillId';

export const LS_TOTAL_RESULTS_MEETING_FILTERS =
  'huglo:totalResultsMeetingFilters';

export const LS_ACTIVE_STEP_URL = 'huglo:activeStepUrl';
export const LS_REGION_POSTAL_CODE = 'huglo:regionPostalCode';

export const LS_RESULTS_TOKEN = 'huglo:resultsToken';
